:root {
  --swiper-theme-color: var(--primary);
  --swiper-navigation-color: rgba(127, 127, 127, 0.5);
  --swiper-pagination-bullet-inactive-color: rgba(127, 127, 127);
  --swiper-navigation-size: 16px;
}

@media only screen and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background: var(--content-main);
  color: var(--content-main-contrast);
  height: 32px;
  width: 32px;
  border-radius: 9999px;
  top: calc(50% - 16px);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.4);
}

.swiper-button-disabled {
  display: none;
}

.swiper-horizontal-pagination {
  display: flex;
  position: absolute;
  left: 8px !important;
  bottom: 8px !important;
  width: calc(100% - 16px) !important;
  height: 4px;
  justify-content: center;
}

.swiper-horizontal-pagination-bullet {
  display: inline-flex;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 9999px;
  aspect-ratio: 4 / 1;
}

.swiper-horizontal-pagination-bullet:not(:first-child) {
  margin-left: 4px;
}

.swiper-horizontal-pagination-bullet-active {
  background: #fff !important;
  aspect-ratio: 8 / 1;
}
