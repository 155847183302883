/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper/css/effect-creative';
@import 'swiper/css/navigation';
@import 'swiper/css/free-mode';
@import 'tailwindcss/tailwind.css';
@import 'nprogress/nprogress.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';

@import './styles/theme.css';
@import './styles/fonts.css';
@import './styles/animation.css';
@import './styles/swiper.css';
@import './styles/sportradar.css';

@layer components {
  .text-neutral {
    @apply text-gray-500 text-opacity-70;
  }
}

html {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: clip;

  height: -webkit-fill-available;
}

body {
  background: var(--background);
  color: var(--background-contrast);
  width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;

  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

* {
  scrollbar-width: none;
}

.border-neutral {
  border-color: var(--border-neutral);
}

#nprogress .bar {
  background: linear-gradient(to left, var(--progress-bar-to), var(--progress-bar-from)) !important;
  height: 3px !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #000, 0 0 5px #fff !important;
}

.scrollable {
  overflow: auto;
}

.not-scrollable {
  overflow: hidden;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

*::-webkit-scrollbar {
  display: none;
}

input::-webkit-date-and-time-value {
  text-align: left;
}

.shadow-card-soft {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}

.shadow-card {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
}

.shadow-evenly {
  box-shadow: 5px 5px 9px 3px rgba(0, 0, 0, 0.1), -5px 0 9px 3px rgba(0, 0, 0, 0.05);
}

.card-image-clip {
  mask-image: -webkit-gradient(
    linear,
    left center,
    right center,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(0.5, rgba(0, 0, 0, 0.75)),
    color-stop(1, rgba(0, 0, 0, 1))
  );
  -webkit-mask-image: -webkit-gradient(
    linear,
    left center,
    right center,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(0.5, rgba(0, 0, 0, 0.75)),
    color-stop(1, rgba(0, 0, 0, 1))
  );
}

.bg-bet-slip-item-error {
  background: repeating-linear-gradient(
    -45deg,
    var(--betslip-item),
    var(--betslip-item) 16px,
    rgba(0, 0, 0, 0.03) 16px,
    rgba(0, 0, 0, 0.03) 32px
  );
}

.break-after-every-word {
  word-spacing: 100vw;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: var(--card);
}

.skeleton-content {
  position: relative;
  overflow: hidden;
  background-color: var(--card-contrast);
  opacity: 0.1;
  animation: skeleton-fade 1.5s ease-in-out infinite;
}

@keyframes skeleton-fade {
  0%,100% { opacity: .05 }
  50% { opacity: 0.1 }
}
