:root {
    touch-action: pan-x pan-y;
    user-select: none;

    --app-max-width: 1520px;
    --header-height: 52px;
    --bottom-nav-height: 52px;
    --widget-container-left-width: 320px;
    --widget-container-right-width: 400px;

    --background: #ffffff;
    --background-contrast: #000000;
    --border-neutral: #88888822;
    --primary: #fed230;
    --primary-contrast: #000000;
    --secondary: #62b441;
    --secondary-contrast: #ffffff;

    --form: rgba(115, 115, 115, 0.1);
    --form-contrast: var(--background-contrast);
    --form-border: rgba(115, 115, 115, 1);
    --form-hover: var(--form);
    --form-hover-contrast: var(--form-contrast);
    --form-hover-border: var(--form-border);

    --header: var(--primary);
    --header-contrast: var(--primary-contrast);

    --footer: #171717;
    --footer-contrast: #d4d4d4;
    --footer-fade-from: var(--primary);
    --footer-fade-to: var(--secondary);

    --sidenav: var(--header);
    --sidenav-contrast: var(--header-contrast);

    --card: #e5e5e5;
    --card-contrast: #000000;
    --card-hover: var(--card);
    --card-fade: #000000;
    --card-divider-from: var(--primary);
    --card-divider-to: var(--primary);

    --odd: #f5f5f5;
    --odd-contrast: #000000;
    --odd-border: var(--odd);
    --odd-hover: var(--odd);
    --odd-hover-contrast: var(--odd-contrast);
    --odd-hover-border: var(--odd-border);
    --odd-checking: var(--odd-selected);
    --odd-checking-contrast: var(--odd-selected-contrast);
    --odd-checking-border: var(--odd-selected-border);
    --odd-selected: var(--primary);
    --odd-selected-to: var(--odd-selected);
    --odd-selected-contrast: var(--primary-contrast);
    --odd-selected-border: var(--odd-selected);
    --odd-selected-hover: var(--odd-selected);
    --odd-selected-hover-contrast: var(--odd-selected-contrast);
    --odd-selected-hover-border: var(--odd-selected-border);

    --stake-selector: #e5e5e5;
    --stake-selector-item: #e5e5e5;
    --stake-selector-item-contrast: #a3a3a3;
    --stake-selector-item-active: var(--primary);
    --stake-selector-item-active-contrast: var(--primary-contrast);

    --betslip: var(--background);
    --betslip-contrast: var(--background-contrast);
    --betslip-header: var(--header);
    --betslip-header-contrast: var(--header-contrast);
    --betslip-item: #f5f5f5;
    --betslip-item-contrast: #000000;

    --progress-bar-from: var(--primary);
    --progress-bar-to: var(--primary);

    --app-loader-background: var(--primary);
    --app-loader-text: var(--primary-contrast);
    --app-loader-animation: var(--secondary);

    --onboarding-background: var(--background);
    --onboarding-text: var(--background-contrast);
    --onboarding-image: var(--primary);
    --onboarding-cta: var(--secondary);

    --live: #e33161;
    --live-contrast: #ffffff;

    --odd-size: 52px;
    --odd-gap: 4px;
    --odd-radius: 3px;

    --content-offset: 52px;

    --slb-sport-item: var(--card);
    --slb-sport-item-contrast: var(--card-contrast);
    --slb-category-item: var(--background);
    --slb-category-item-contrast: var(--background-contrast);

    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);

    --bottom-nav: var(--header);
    --bottom-nav-contrast: var(--header-contrast);

    --content-left: var(--background);
    --content-left-contrast: var(--background-contrast);
    --content-main: var(--background);
    --content-main-contrast: var(--background-contrast);
    --content-right: var(--background);
    --content-right-contrast: var(--background-contrast);
}