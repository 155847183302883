@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }
  to {
    -webkit-mask-position: -50%;
  }
}

.animate-shine {
  -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.7) 30%, #000 50%, rgba(0, 0, 0, 0.7) 70%);
  -webkit-mask-size: 200%;
  animation: shine 3s linear infinite;
}

.pulse-green {
  box-shadow: 0 0 0 0 rgba(23, 214, 45, 1);
  animation: pulse-green 2s;
}

.pulse-red {
  box-shadow: 0 0 0 0 rgba(214, 30, 23, 1);
  animation: pulse-red 2s;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(214, 30, 23, 0.7);
  }

  70% {
    box-shadow: 0 0 0 4px rgba(214, 30, 23, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(214, 30, 23, 0);
  }
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(23, 214, 45, 0.7);
  }

  70% {
    box-shadow: 0 0 0 4px rgba(23, 214, 45, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(23, 214, 45, 0);
  }
}

@keyframes scaleAnimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

.animate-scale {
  animation: 0.3s scaleAnimation;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}